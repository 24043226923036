import { useMemo } from 'react';

import { useGetUserQuery } from 'src/common/slices';

export const useAnnouncementCta = (): [string, string] => {
  const { data: userDataResponse } = useGetUserQuery();

  return useMemo(() => {
    if (userDataResponse?.data?.user?.isPremium) {
      return ['Switch to annual', '/manage-account/subscription '];
    }
    return ['Subscribe Now', '/why-subscribe'];
  }, [userDataResponse]);
};
