import { useCallback, useEffect, useMemo } from 'react';

import { Announcement, StoredAnnouncement } from 'src/common/interfaces/announcements';

const storageKey = 'announcementsSeen';

const getSeenAnnouncements = (): StoredAnnouncement[] => {
  try {
    const stored = localStorage.getItem(storageKey) || '';
    return (JSON.parse(stored) || []) as StoredAnnouncement[];
  } catch {
    return [];
  }
};

const storeAnnouncements = (announcements: StoredAnnouncement[]) => {
  localStorage.setItem('announcementsSeen', JSON.stringify(announcements));
};

export const useAnnouncementStorage = (current: Announcement | null) => {
  // Clean up obsolete announcements from storage
  useEffect(() => {
    const announcementsSeen = getSeenAnnouncements();
    const cleanedUp = announcementsSeen.filter((storedAnnouncement) => storedAnnouncement.endTime >= Date.now());

    storeAnnouncements(cleanedUp);
  }, []);

  const markAsSeen = useCallback(() => {
    if (!current) return;

    const announcementsSeen = getSeenAnnouncements();
    storeAnnouncements([
      ...announcementsSeen,
      {
        name: current.name,
        endTime: current.endTime,
      },
    ]);
  }, [current]);

  const isSeen = useMemo(() => {
    if (!current) return false;
    const seen = getSeenAnnouncements();
    return seen.some((an) => an.name === current.name);
  }, [current]);

  return { isSeen, markAsSeen };
};
