import { useEffect, useMemo, useState } from 'react';

import { AnnouncementName } from 'src/common/enums/announcements';
import { Announcement } from 'src/common/interfaces/announcements';
import { useGetUserQuery } from 'src/common/slices';

import { useGetAnnouncementsQuery } from '../announcements.slice';

type AnnouncementHookResult = {
  announcement: Announcement | null;
  shouldPromote: boolean;
};

export const useAnnouncement = (name: AnnouncementName): AnnouncementHookResult => {
  const [timeIsValid, setTimeIsValid] = useState(false);

  const { data: userDataResponse } = useGetUserQuery();
  const { data: announcementsResponse } = useGetAnnouncementsQuery();

  const announcement = useMemo<Announcement | null>(() => {
    if (!announcementsResponse?.data) {
      return null;
    }

    const { data } = announcementsResponse;
    if (data.name !== name) {
      return null;
    }

    return {
      name: data.name,
      amount: data.amount,
      startTime: new Date(data.startDate).getTime(),
      endTime: new Date(data.endDate).getTime(),
    };
  }, [announcementsResponse, name]);

  useEffect(() => {
    if (!announcement) return;

    const checkValidity = () => {
      const now = Date.now();
      setTimeIsValid(announcement.endTime > now);
    };

    const intervalId = setInterval(checkValidity, 1000);
    checkValidity();
    return () => clearInterval(intervalId);
  }, [announcement]);

  return useMemo<AnnouncementHookResult>(() => {
    if (!announcement) {
      return { announcement: null, shouldPromote: false };
    }

    if (!userDataResponse?.data?.user) {
      return { announcement, shouldPromote: timeIsValid };
    }

    const { user } = userDataResponse.data;
    const isNewSignup = Date.now() - new Date(user.dateSignUp).getTime() < 1000 * 60 * 60 * 24;

    return { announcement, shouldPromote: !isNewSignup && timeIsValid };
  }, [announcement, userDataResponse, timeIsValid]);
};
